export const ArrowRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <mask id="path-1-inside-1_2839_134386" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.4H10.69L9.145 6.855L10 6L13 9L10 12L9.145 11.16L10.69 9.6H5V8.4Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 8.4H10.69L9.145 6.855L10 6L13 9L10 12L9.145 11.16L10.69 9.6H5V8.4Z"
      fill={props.fill || 'black'}
    />
    <path
      d="M5 8.4V7.4H4V8.4H5ZM10.69 8.4V9.4H13.1042L11.3971 7.69289L10.69 8.4ZM9.145 6.855L8.43789 6.14789L7.73079 6.855L8.43789 7.56211L9.145 6.855ZM10 6L10.7071 5.29289L10 4.58579L9.29289 5.29289L10 6ZM13 9L13.7071 9.70711L14.4142 9L13.7071 8.29289L13 9ZM10 12L9.29918 12.7133L10.0062 13.408L10.7071 12.7071L10 12ZM9.145 11.16L8.43448 10.4563L7.72797 11.1697L8.44418 11.8733L9.145 11.16ZM10.69 9.6L11.4005 10.3037L13.0878 8.6H10.69V9.6ZM5 9.6H4V10.6H5V9.6ZM5 9.4H10.69V7.4H5V9.4ZM11.3971 7.69289L9.85211 6.14789L8.43789 7.56211L9.98289 9.10711L11.3971 7.69289ZM9.85211 7.56211L10.7071 6.70711L9.29289 5.29289L8.43789 6.14789L9.85211 7.56211ZM9.29289 6.70711L12.2929 9.70711L13.7071 8.29289L10.7071 5.29289L9.29289 6.70711ZM12.2929 8.29289L9.29289 11.2929L10.7071 12.7071L13.7071 9.70711L12.2929 8.29289ZM10.7008 11.2867L9.84582 10.4467L8.44418 11.8733L9.29918 12.7133L10.7008 11.2867ZM9.85551 11.8637L11.4005 10.3037L9.97949 8.89632L8.43448 10.4563L9.85551 11.8637ZM10.69 8.6H5V10.6H10.69V8.6ZM6 9.6V8.4H4V9.6H6Z"
      fill={props.fill || 'black'}
      mask="url(#path-1-inside-1_2839_134386)"
    />
  </svg>
)

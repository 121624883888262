export const Person = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none">
    <g clipPath="url(#clip0_2266_109818)">
      <path
        d="M10.0007 4.99992C10.9173 4.99992 11.6673 5.74992 11.6673 6.66659C11.6673 7.58325 10.9173 8.33325 10.0007 8.33325C9.08398 8.33325 8.33398 7.58325 8.33398 6.66659C8.33398 5.74992 9.08398 4.99992 10.0007 4.99992ZM10.0007 13.3333C12.2507 13.3333 14.834 14.4083 15.0007 14.9999H5.00065C5.19232 14.3999 7.75898 13.3333 10.0007 13.3333ZM10.0007 3.33325C8.15898 3.33325 6.66732 4.82492 6.66732 6.66659C6.66732 8.50825 8.15898 9.99992 10.0007 9.99992C11.8423 9.99992 13.334 8.50825 13.334 6.66659C13.334 4.82492 11.8423 3.33325 10.0007 3.33325ZM10.0007 11.6666C7.77565 11.6666 3.33398 12.7833 3.33398 14.9999V16.6666H16.6673V14.9999C16.6673 12.7833 12.2257 11.6666 10.0007 11.6666Z"
        fill="#0179FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2266_109818">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

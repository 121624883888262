import { ArrowLeftRightInCircle } from '@src/components/common/svgs/ArrowLeftRightInCircle'
import { useSSRRecoilState } from '@src/hooks/useSSRRecoilState'
import {
  isBasicFlowState,
  isSearchModalOpenState,
  searchTypeState,
} from '@src/store/atoms/search/common'
import {
  arrivalState,
  departureState,
} from '@src/store/atoms/search/travelDestination'
import { TRAVEL_DESTINATION_DEFAULT } from '@src/store/constants/search/defaultValue'
import { useSetRecoilState } from 'recoil'
import { twJoin } from 'tailwind-merge'
import { match } from 'ts-pattern'

export const TravelDestination = () => {
  const setIsSearchModalOpen = useSetRecoilState(isSearchModalOpenState)
  const setIsBasicFlow = useSetRecoilState(isBasicFlowState)
  const setSearchType = useSetRecoilState(searchTypeState)
  const [departure, setDeparture] = useSSRRecoilState(
    TRAVEL_DESTINATION_DEFAULT,
    departureState
  )
  const [arrival, setArrival] = useSSRRecoilState(
    TRAVEL_DESTINATION_DEFAULT,
    arrivalState
  )

  const isDepartureExist = Boolean(departure.code)
  const isArrivalExist = Boolean(arrival.code)

  const handleDepartureClick = () => {
    if (!departure.code && !arrival.code) {
      setIsBasicFlow(true)
    } else {
      setIsBasicFlow(false)
    }

    setSearchType('DEPARTURE')
    setIsSearchModalOpen(true)
  }

  const handleArrivalClick = () => {
    if (departure.code) {
      setIsBasicFlow(false)
      setSearchType('ARRIVAL')
      setIsSearchModalOpen(true)
    } else {
      alert('출발지를 먼저 선택해 주세요.')
    }
  }

  const handleTargetChange = () => {
    match({ departure, arrival })
      .with({ departure, arrival: TRAVEL_DESTINATION_DEFAULT }, () => {
        setDeparture(TRAVEL_DESTINATION_DEFAULT)
        setArrival(departure)
      })
      .with({ departure: TRAVEL_DESTINATION_DEFAULT, arrival }, () => {
        setDeparture(arrival)
        setArrival(TRAVEL_DESTINATION_DEFAULT)
      })
      .with({ departure, arrival }, () => {
        setDeparture(arrival)
        setArrival(departure)
      })
  }

  return (
    <div className="tw-relative tw-flex">
      <div
        className={twJoin(
          'tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-gap-y-2 tw-rounded-l-16',
          'tw-border-1  tw-border-solid tw-border-grey030 tw-bg-white tw-px-20',
          isDepartureExist ? 'tw-py-14' : 'tw-py-24'
        )}
        role="button"
        onClick={handleDepartureClick}>
        <span
          className={twJoin(
            'title1 tw-font-semibold tw-text-grey045',
            isDepartureExist && ' tw-text-text-initial'
          )}>
          {isDepartureExist ? departure.name : '출발지'}
        </span>
        {isDepartureExist && (
          <span className="title4 tw-font-normal tw-text-text-secondary">
            {departure.code}
          </span>
        )}
      </div>
      <div
        className={twJoin(
          'tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center tw-gap-y-2 tw-rounded-r-16',
          'tw-border-1 tw-border-l-0 tw-border-solid  tw-border-grey030 tw-bg-white tw-px-20',
          isArrivalExist ? 'tw-py-14' : 'tw-py-24'
        )}
        role="button"
        onClick={handleArrivalClick}>
        <span
          className={twJoin(
            'title1 tw-font-semibold tw-text-grey045',
            isArrivalExist && 'tw-text-text-initial'
          )}>
          {isArrivalExist ? arrival.name : '도착지'}
        </span>
        {isArrivalExist && (
          <span className="title4 tw-font-normal tw-text-text-secondary">
            {arrival.code}
          </span>
        )}
      </div>
      <div
        className="tw-absolute tw-left-half tw-top-half tw-translate-half"
        onClick={handleTargetChange}>
        <ArrowLeftRightInCircle />
      </div>
    </div>
  )
}

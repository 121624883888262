import { RecentSearch } from '@src/store/atoms/search/recentSearch'

export const refinedPersonnelSeatTypes = ({
  adultCount,
  childCount,
  infantCount,
  seatTypes,
}: Pick<
  RecentSearch,
  'adultCount' | 'childCount' | 'infantCount' | 'seatTypes'
>) => {
  let personnelSeatTypesParts = []
  const selectedSeatTypes = seatTypes.filter((seatType) => seatType.selected)

  if (adultCount > 0) {
    personnelSeatTypesParts.push(`성인${adultCount}`)
  }

  if (childCount > 0) {
    personnelSeatTypesParts.push(`소아${childCount}`)
  }

  if (infantCount > 0) {
    personnelSeatTypesParts.push(`유아${infantCount}`)
  }

  if (selectedSeatTypes.length === 5) {
    personnelSeatTypesParts.push('전체')
  } else {
    personnelSeatTypesParts.push(
      selectedSeatTypes.map((seatType) => seatType.name).join(', ')
    )
  }

  return personnelSeatTypesParts.join(', ')
}

import { itineraryTypeState } from '@src/store/atoms/search/common'
import {
  travelEndDateState,
  travelStartDateState,
} from '@src/store/atoms/search/date'
import {
  adultCountState,
  childCountState,
  infantCountState,
  seatTypesState,
} from '@src/store/atoms/search/personnel'
import {
  RecentSearch,
  recentSearchesState,
} from '@src/store/atoms/search/recentSearch'
import {
  arrivalState,
  departureState,
} from '@src/store/atoms/search/travelDestination'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import dayjs from 'dayjs'
import { diff } from 'deep-object-diff'
import { useRecoilCallback } from 'recoil'

dayjs.extend(isSameOrAfter)

export const useUpdateRecentSearches = () => {
  const getRemovedRecentSearches = (recentSearches: RecentSearch[]) => {
    const today = dayjs()

    const updatedRecentSearches = recentSearches.filter(
      ({ travelStartDate }) => {
        const targetDate = dayjs(travelStartDate)

        return targetDate.isSameOrAfter(today, 'day')
      }
    )

    return updatedRecentSearches
  }

  return useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const [
          itineraryType,
          departure,
          arrival,
          travelStartDate,
          travelEndDate,
          adultCount,
          childCount,
          infantCount,
          seatTypes,
        ] = await Promise.all([
          snapshot.getPromise(itineraryTypeState),
          snapshot.getPromise(departureState),
          snapshot.getPromise(arrivalState),
          snapshot.getPromise(travelStartDateState),
          snapshot.getPromise(travelEndDateState),
          snapshot.getPromise(adultCountState),
          snapshot.getPromise(childCountState),
          snapshot.getPromise(infantCountState),
          snapshot.getPromise(seatTypesState),
        ])

        const recentSearches = await snapshot.getPromise(recentSearchesState)

        const newRecentSearch = {
          itineraryType,
          departure,
          arrival,
          travelStartDate,
          travelEndDate,
          adultCount,
          childCount,
          infantCount,
          seatTypes,
        } as RecentSearch

        const duplicatedIndex = recentSearches.findIndex(
          (recentSearch) =>
            Object.keys(diff(newRecentSearch, recentSearch)).length === 0
        )

        // 중복된 검색이 있을 경우, 해당 검색을 최상단으로 이동
        if (duplicatedIndex !== -1) {
          const newRecentSearches = [
            newRecentSearch,
            ...recentSearches.slice(0, duplicatedIndex),
            ...recentSearches.slice(duplicatedIndex + 1),
          ]

          set(recentSearchesState, getRemovedRecentSearches(newRecentSearches))
        } else if (recentSearches.length === 5) {
          const newRecentSearches = recentSearches.slice(0, 4)

          set(
            recentSearchesState,
            getRemovedRecentSearches([newRecentSearch, ...newRecentSearches])
          )
        } else {
          set(
            recentSearchesState,
            getRemovedRecentSearches([newRecentSearch, ...recentSearches])
          )
        }
      },
    []
  )
}

import { LoadingLottie } from '@src/components/ui/CTAButton'
import { useLoadingAnimation } from '@src/hooks/useLoadingAnimation'
import { useEffect } from 'react'
import { twJoin } from 'tailwind-merge'

interface PendingProps {
  content?: string
  description?: string
}

export const Pending = ({ content, description }: PendingProps) => {
  const loadingAnimation = useLoadingAnimation('0069ff')

  useEffect(function handleScrollBlock() {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <div
      className={twJoin(
        'tw-fixed tw-left-0 tw-top-0 tw-z-[99999] tw-flex tw-h-[100vh] tw-w-[100vw] tw-flex-col tw-items-center tw-justify-center tw-bg-dim-thick tw-bg-fixed'
      )}>
      <div className="tw-flex tw-h-[54px] tw-w-[54px] tw-rounded-full tw-bg-white">
        <LoadingLottie
          loadingAnimation={loadingAnimation}
          width={36}
          height={12}
        />
      </div>
      {content && (
        <div className="body1 tw-whitespace-pre-line tw-pb-12 tw-pt-18 tw-text-center tw-font-semibold tw-text-white">
          {content}
        </div>
      )}
      {description && (
        <div className="body2 tw-whitespace-pre-line tw-px-16 tw-text-center tw-text-white">
          {description}
        </div>
      )}
    </div>
  )
}

import { ScheduleDto } from '@src/api'
import dayjs from 'dayjs'
import numeral from 'numeral'
import { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { match } from 'ts-pattern'
import { FlightsFilter } from './FilterBottomSheet'
import { FlightStatus, SORT_BY, SortBy } from './types'

/**
 *
 */
export const formatFlightTime = (minutes: number): string => {
  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  return `${numeral(hours).format('00')}${numeral(mins).format('00')}`
}

/**
 *
 * @param topasTime 0615, 0730, 1230, 0105, ...
 * @returns 6시간 15분, 7시간 30분, 12시간 30분, 1시간 5분, ...
 */
export const formatTimeForKorean = (topasTime: string) => {
  const hours = topasTime.slice(0, 2)
  const minutes = topasTime.slice(2)
  return `${Number(hours)}시간 ${Number(minutes)}분`
}

/**
 *
 * @param topasTime 0615, 0730, 1230, ...
 * @returns 06:15, 07:30, 12:30, ...
 */
export const formatTimeWithColons = (topasTime: string) =>
  `${topasTime.slice(0, 2)}:${topasTime.slice(2)}`

/**
 * TODO match 로 추후에 변경합시다
 * @param sortBy
 * @returns
 */
export const handleSortBy = (sortBy: SortBy) => {
  if (sortBy === SORT_BY.PRICE_ASC) {
    return (a: ScheduleDto, b: ScheduleDto) =>
      Number(a.displayAmount) - Number(b.displayAmount)
  } else if (sortBy === SORT_BY.DEPARTURE_TIME_ASC) {
    return (a: ScheduleDto, b: ScheduleDto) =>
      dayjs(`${a.depDate}${a.depTime}`).unix() -
      dayjs(`${b.depDate}${b.depTime}`).unix()
  } else if (sortBy === SORT_BY.DEPARTURE_TIME_DESC) {
    return (a: ScheduleDto, b: ScheduleDto) =>
      dayjs(`${b.depDate}${b.depTime}`).unix() -
      dayjs(`${a.depDate}${a.depTime}`).unix()
  }
}

export const updateFilters = (
  setFilters: Dispatch<SetStateAction<FlightsFilter[]>>,
  code: string
) => {
  if (code === 'A') {
    setFilters((prev) => {
      const allSelected = prev.every((filter) => filter.selected)
      return prev.map((filter) => ({
        ...filter,
        selected: !allSelected,
      }))
    })
    return
  }

  setFilters((prev) => {
    const newFilters = prev.map((filter) =>
      filter.code === code ? { ...filter, selected: !filter.selected } : filter
    )
    const isAllSelected = newFilters
      .filter(({ code }) => code !== 'A')
      .every((filter) => filter.selected)

    return newFilters.map((filter) =>
      filter.code === 'A' ? { ...filter, selected: isAllSelected } : filter
    )
  })
}

/**
 *
 * @param isOneWay
 * @param isOutBoundSelected
 * @param isInBoundSelected
 * @param isOutBoundReserved
 * @returns
 */
export const getFlightStatus = ({
  isOneWay,
  isOutBoundSelected,
  isInBoundSelected,
  isOutBoundReserved,
}: {
  isOneWay: boolean
  isOutBoundSelected: boolean
  isInBoundSelected: boolean
  isOutBoundReserved?: boolean
}): FlightStatus => {
  const status = match({
    isOneWay,
    isOutBoundSelected,
    isInBoundSelected,
    isOutBoundReserved,
  })
    .with(
      { isOutBoundReserved: true, isInBoundSelected: false },
      () => FlightStatus.ROUND_TRIP_OUTBOUND_RESERVED
    )
    .with(
      { isOutBoundReserved: true, isInBoundSelected: true },
      () => FlightStatus.ROUND_TRIP_ONLY_INBOUND_SELECTED
    )
    .with(
      { isOneWay: true, isOutBoundSelected: false, isInBoundSelected: false },
      () => FlightStatus.ONE_WAY_UNSELECTED
    )
    .with(
      { isOneWay: true, isOutBoundSelected: true, isInBoundSelected: false },
      () => FlightStatus.ONE_WAY_SELECTED
    )
    .with(
      {
        isOneWay: false,
        isOutBoundSelected: false,
        isInBoundSelected: false,
      },
      () => FlightStatus.ROUND_TRIP_UNSELECTED
    )
    .with(
      { isOneWay: false, isOutBoundSelected: true, isInBoundSelected: false },
      () => FlightStatus.ROUND_TRIP_OUTBOUND_SELECTED
    )
    .with(
      { isOneWay: false, isOutBoundSelected: true, isInBoundSelected: true },
      () => FlightStatus.ROUND_TRIP_INBOUND_SELECTED
    )

    .otherwise(() => FlightStatus.UNSPECIFIED)

  return status
}

const usePrevious = (value: any, initialValue: any) => {
  const ref = useRef(initialValue)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useEffectDebugger = (
  effectHook: any,
  dependencies: any,
  dependencyNames = []
) => {
  const previousDeps = usePrevious(dependencies, [])

  const changedDeps = dependencies.reduce(
    (accum: any, dependency: any, index: any) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        }
      }

      return accum
    },
    {}
  )

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps)
  }

  useEffect(effectHook, dependencies)
}

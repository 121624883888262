import { withHeadTitle } from '@src/hocs'
import { Main } from '@src/views/Main'
import { GetServerSideProps, type NextPage } from 'next'
import { getSession } from 'next-auth/react'

interface PageProps {
  isLoggedIn: boolean
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context)
  const isLoggedIn = Boolean(session?.user?.isLoggedIn)

  return {
    props: {
      isLoggedIn,
    },
  }
}

const Page: NextPage<PageProps> = ({ isLoggedIn }) =>
  withHeadTitle(<Main isLoggedIn={isLoggedIn} />, '항공')

export default Page

import {
  AGREEMENT_ZENDESK_LINK,
  PRIVACY_POLICY_ZENDESK_LINK,
} from '@src/components/common/layout/constants'
import Link from 'next/link'

// TODO : 링크 정해지면 교체
export const Footer = () => {
  return (
    <footer className="tw-flex tw-flex-col tw-gap-y-16 tw-bg-grey025 tw-px-16 tw-pb-56 tw-pt-24">
      <nav className="title3 tw-flex tw-flex-wrap tw-gap-x-24 tw-gap-y-10 tw-font-normal tw-text-text-primary">
        <Link
          href={PRIVACY_POLICY_ZENDESK_LINK}
          target="_blank"
          rel="noopenner noreferer"
          className="title3 tw-font- tw-text-navy050">
          개인정보처리방침
        </Link>
        <Link
          href={AGREEMENT_ZENDESK_LINK}
          target="_blank"
          rel="noopenner noreferer">
          이용약관
        </Link>
        <Link href="/faq">FAQ</Link>
      </nav>
      <section className="subtitle4 tw-font-normal tw-text-grey050">
        <div>주소: 제주특별자치도 제주시 연미길 42(오라삼동)</div>
        <div>전화: 1670-3077</div>
        <div>팩스: 02-6969-9333</div>
        <div>※ 업무시간 안내</div>
        <div>• 평일 09:00~17:00 (점심시간 12:00~13:00)</div>
        <div>• 토/일 및 공휴일 휴무</div>
      </section>
      <section className="subtitle4 tw-font-normal tw-text-grey050">
        <div>
          (주)쏘카 통신판매업{' '}
          <Link
            href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=6168190529&apv_perm_no="
            target="_blank"
            className="tw-underline">
            사업자정보 확인
          </Link>
        </div>
        <div>신고: 제 2019-제주오라-3호, 사업자등록번호: 616-81-90529,</div>
        <div>대표자: 박재욱</div>
        <div>관광사업자번호 제2018-12호</div>
        <div>© {new Date().getFullYear()} SOCAR All rights reserved.</div>
      </section>
      <section className="subtitle4 tw-font-normal tw-text-text-tertiary">
        <div>
          쏘카 항공에서 (주)쏘카는 통신판매중개업자로서 통신판매의 당사자가
          아니라는 사실을 고지합니다.
        </div>
        <div>
          해당 항공권의 상품 정보와 결제 및 발급 과정에 관한 의무와 책임은 각
          항공사에 있습니다. 항공권 예약 내역 관리 과정에 관한 의무와 책임은
          (주)쏘카에 있습니다.
        </div>
      </section>
    </footer>
  )
}

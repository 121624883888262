import { BottomSheet } from '@src/components/ui/bottom-sheet'
import { useGoToSocialSignUp } from '@src/hooks/useGoToSocialSignUp'
import LoginBottomSheet from '@src/views/Login/LoginBottomSheet'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

interface Props {
  isLoggedIn: boolean
}

const ReservationButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      role="button"
      className="title3 tw-font-normal tw-text-text-primary"
      onClick={onClick}>
      예약 내역
    </button>
  )
}

const ReservationCTAButton = ({ isLoggedIn }: Props) => {
  const { goToSocialSignUp } = useGoToSocialSignUp()
  const router = useRouter()

  const { OPEN_LOGIN_BOTTOM_SHEET } = router.query

  const [isLoginBottomSheetOpen, setIsLoginBottomSheetOpen] = useState(false)

  const handleClickReservation = () => {
    if (isLoggedIn) {
      router.push('/reservations')
    } else {
      setIsLoginBottomSheetOpen(true)
    }
  }

  const handleRouteChangeComplete = () => {
    if (OPEN_LOGIN_BOTTOM_SHEET === 'true') {
      setIsLoginBottomSheetOpen(true)
    }
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // https://www.figma.com/design/ImXYjFXsL5tNuQaO6l2z9F?node-id=3255-80607&m=dev#925940383
  return (
    <>
      <ReservationButton onClick={handleClickReservation} />
      {!isLoggedIn && (
        <BottomSheet.Root
          onBeforeClose={() => {
            setIsLoginBottomSheetOpen(false)
          }}>
          <LoginBottomSheet
            isOpen={isLoginBottomSheetOpen}
            reason="예약 내역 확인을 위해"
            guestButtonText="비회원 예약조회"
            handleMemberLogin={() => goToSocialSignUp('/reservations')}
            handleGuestLogin={() =>
              router.push('/reservations-guest/check-info')
            }
          />
        </BottomSheet.Root>
      )}
    </>
  )
}

export default ReservationCTAButton

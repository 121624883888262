import { atom } from 'recoil'
import { TravelDestination } from './travelDestination'
import { ItineraryType } from './common'
import { SeatType } from './personnel'
import { RECENT_SEARCHES } from '@src/store/constants/search/atomsKey'
import { storageEffect } from '@src/utils/storageEffect'

export interface RecentSearch {
  itineraryType: ItineraryType
  departure: TravelDestination
  arrival: TravelDestination
  travelStartDate: Date
  travelEndDate: Date
  adultCount: number
  childCount: number
  infantCount: number
  seatTypes: SeatType[]
}

export const recentSearchesState = atom<RecentSearch[]>({
  key: RECENT_SEARCHES,
  default: [],
  effects: [storageEffect('recentSearches', 'local')],
})

export interface RefinedRecentSearch
  extends Pick<RecentSearch, 'itineraryType' | 'departure' | 'arrival'> {
  travelDate: string
  personnelSeatTypes: string
}

import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Banner } from './Banner'
import { useGetBannersQuery } from './queries'
import { BannerSwiperProps } from './types'

// 최대 슬라이드 기획에 따라 9개 제한
const SWIPER_MAX_SLIDES = 9
const VOF_API = process.env.NEXT_PUBLIC_VOF_API

export const BannerSwiper = ({ position }: BannerSwiperProps) => {
  const { data: banners } = useGetBannersQuery({
    position,
  })

  return (
    <Swiper
      className="tw-mb-32 tw-mt-16 tw-w-full"
      loop
      pagination={{
        type: 'bullets',
        clickable: true,
        horizontalClass: 'pagination-wrapper',
        bulletClass:
          'tw-h-6 tw-w-6 tw-cursor-pointer tw-inline-block tw-rounded-half tw-bg-opacity-15 tw-bg-grey080 tw-mx-4',
        bulletActiveClass: 'tw-bg-opacity-50',
      }}
      modules={[Pagination]}>
      {banners?.data
        ?.filter((banner) => banner.display === 'Y')
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .slice(0, SWIPER_MAX_SLIDES)
        .map((banner) => (
          <SwiperSlide key={banner.seqNo} className="tw-text-center">
            <Banner
              position={banner.position}
              linkUrl={banner.pcLinkUrl || '#'}
              src={`${VOF_API}${banner.pcImgPath}`}
              alt={banner.title}
            />
          </SwiperSlide>
        ))}
    </Swiper>
  )
}

export const ArrowLeftRight = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none">
    <path
      d="M6 12L3 9L6 6L6.84 6.855L5.295 8.4H12.69L11.145 6.855L12 6L15 9L12 12L11.145 11.16L12.69 9.6H5.295L6.84 11.16L6 12Z"
      fill={props.fill || '#17191C'}
    />
  </svg>
)
